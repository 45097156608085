import Storage from '@/vendor/storage'

const scroll = (elements) => {
	return {
		created() {
			elements.forEach((item, key) => {
				Storage.sessionStorage.delete(getKey(item, key, 'top'))
				Storage.sessionStorage.delete(getKey(item, key, 'left'))
			})
		},
		activated() {
			elements.forEach((item, key) => {
				let domNode = document.querySelector(item);
				
				if (domNode) {
					domNode.scrollTop = Storage.sessionStorage.get(getKey(item, key, 'top'))
					domNode.scrollLeft = Storage.sessionStorage.get(getKey(item, key, 'left'))
				}
			})
		},
		beforeRouteLeave(to, from, next) {
			elements.forEach((item, key) => {
				let domNode = document.querySelector(item);

				if (domNode) {
					Storage.sessionStorage.set(getKey(item, key, 'top'), domNode.scrollTop)
					Storage.sessionStorage.set(getKey(item, key, 'left'), domNode.scrollLeft)
				}
			})
			next()
		}
	}
}

const getKey = (item, key, direction) => {
	return `scorll_${item}_${direction}_${key}`
}

export default scroll
